<template>
<div  v-if="isLogin" class="content">
 <el-card class="box-card">

      <div class="card-body">
        <p>昵称：{{ nickName }}</p>
        <p>更新时间：{{ timestamp }}</p>
      </div>
    
    </el-card></div>
  <div v-if="!isLogin" class="content">
     <el-card class="box-card">
    <div class="card">
      <div class="card-header">
        <div class="flex items-center justify-between">
          <p class="card-title">京东扫码登录</p>
        </div>
        <span class="card-subtitle">
          请点击下方按钮登录，点击按钮后回到本网站查看是否登录成功，京东的升级提示不用管。
        </span>
      </div>
      <div class="card-body text-center">
        <div v-if="!qrCodeVisibility" class="flex flex-col w-48 m-auto mt-4">
          <el-button type="primary" round @click="showQrcode"
          >扫描二维码登录</el-button
          >
        </div>
        <img v-else :src="QRCode" :width="256" class="m-auto" />
      </div>
      <div class="card-footer"></div>
    </div>
    
</el-card>
  </div>
  
  
</template>

<script>
import {qrcode, check, userinfo, jdSetInfo,jdApi} from '../api/index';

// var QRCode = require('qrcode');
// var got = require('got');
// var api = got.extend({
//   retry: { limit: 0 },
//   responseType: 'json',
// });
export default {
  name: "hellop",
  data() {
    return {
      index: 0, timer: null, QRCode: '', qrCodeVisibility: false,isLogin:false,nickName:'',timestamp:'',ua : "JD4iPhone/10.0.10 CFNetwork/1206 Darwin/20.1.0"
    }
  },
  methods: {
    showQrcode() {
      const taskUrl = `https://plogin.m.jd.com/cgi-bin/mm/new_login_entrance?lang=chs&appid=300&returnurl=https://wq.jd.com/passport/LoginRedirect?state=${Date.now()}&returnurl=https://home.m.jd.com/myJd/newhome.action?sceneval=2&ufc=&/myJd/home.action&source=wq_passport`;
jdApi({}).then(res=>{
  console.log(res);
})
    //   const response = await api({
    //     url: taskUrl,
    //     headers: {
    //       Connection: 'Keep-Alive',
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //       Accept: 'application/json, text/plain, */*',
    //       'Accept-Language': 'zh-cn',
    //       Referer: taskUrl,
    //       'User-Agent': this.ua,
    //       Host: 'plogin.m.jd.com',
    //     },
    //   });
    //   const headers = response.headers;
    //   const data = response.body;
    //   await this.formatSetCookies(headers, data);

    //   if (!this.s_token) {
    //         throw new Error('二维码创建失败！');
    //   }

    // const nowTime = Date.now();
    // const taskPostUrl = `https://plogin.m.jd.com/cgi-bin/m/tmauthreflogurl?s_token=${
    //   this.s_token
    // }&v=${nowTime}&remember=true`;

    // const configRes = await api({
    //   method: 'post',
    //   url: taskPostUrl,
    //   body: `lang=chs&appid=300&source=wq_passport&returnurl=https://wqlogin2.jd.com/passport/LoginRedirect?state=${nowTime}&returnurl=//home.m.jd.com/myJd/newhome.action?sceneval=2&ufc=&/myJd/home.action`,
    //   headers: {
    //     Connection: 'Keep-Alive',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     Accept: 'application/json, text/plain, */*',
    //     'Accept-Language': 'zh-cn',
    //     Referer: taskUrl,
    //     'User-Agent': this.ua,
    //     Host: 'plogin.m.jd.com',
    //     Cookie: this.cookies,
    //   },
    // });
    // const configHeaders = configRes.headers;
    // const configData = configRes.body;

    // this.token = configData.token;
    // if (this.token)
    //   this.QRCode = await QRCode.toDataURL(
    //     `https://plogin.m.jd.com/cgi-bin/m/tmauth?appid=300&client_type=m&token=${this.token}`
    //   );
    // console.log(QRCode)
    // const cookies = configHeaders['set-cookie'][0];
    // this.okl_token = cookies.substring(cookies.indexOf('=') + 1, cookies.indexOf(';'));


    }, copyToClip(content) {
      var aux = document.createElement("input");
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },jumpLogin(){
      // qrcode().then(res=>{
      //   const href = "openapp.jdmobile://virtual/ad?params={\"category\":\"jump\",\"des\":\"ThirdPartyLogin\",\"action\":\"to\",\"onekeylogin\":\"return\",\"url\":\"https://plogin.m.jd.com/cgi-bin/m/tmauth?appid=300&client_type=m&token="+res.data.token+",\"authlogin_returnurl\":\"weixin://\",\"browserlogin_fromurl\":"+window.location.host+"}"
      //   window.location.href = href
      // })
    },formatSetCookies(headers, data){
    //   return new Promise((resolve) => {
    //   let guid, lsid, ls_token;
    //   this.s_token = data.s_token;
    //   guid = headers['set-cookie'][0];
    //   guid = guid.substring(guid.indexOf('=') + 1, guid.indexOf(';'));
    //   lsid = headers['set-cookie'][2];
    //   lsid = lsid.substring(lsid.indexOf('=') + 1, lsid.indexOf(';'));
    //   ls_token = headers['set-cookie'][3];
    //   ls_token = ls_token.substring(ls_token.indexOf('=') + 1, ls_token.indexOf(';'));
    //   this.cookies = `guid=${guid};lang=chs;lsid=${lsid};ls_token=${ls_token};`;
    //   resolve();
    // });
    }, async checkQRLogin(){
    //   if (!this.token || !this.okl_token || !this.cookies) {
    //   throw new Error('初始化登录请求失败！');
    // }
    // const nowTime = Date.now();
    // const loginUrl = `https://plogin.m.jd.com/login/login?appid=300&returnurl=https://wqlogin2.jd.com/passport/LoginRedirect?state=${nowTime}&returnurl=//home.m.jd.com/myJd/newhome.action?sceneval=2&ufc=&/myJd/home.action&source=wq_passport`;
    // const getUserCookieUrl = `https://plogin.m.jd.com/cgi-bin/m/tmauthchecktoken?&token=${this.token}&ou_state=0&okl_token=${this.okl_token}`;
    // console.log(this.ua);
    // const response = await api({
    //   method: 'POST',
    //   url: getUserCookieUrl,
    //   body: `lang=chs&appid=300&source=wq_passport&returnurl=https://wqlogin2.jd.com/passport/LoginRedirect?state=${nowTime}&returnurl=//home.m.jd.com/myJd/newhome.action?sceneval=2&ufc=&/myJd/home.action`,
    //   headers: {
    //     Connection: 'Keep-Alive',
    //     'Content-Type': 'application/x-www-form-urlencoded; Charset=UTF-8',
    //     Accept: 'application/json, text/plain, */*',
    //     'Accept-Language': 'zh-cn',
    //     Referer: loginUrl,
    //     'User-Agent': this.ua,
    //     Cookie: this.cookies,
    //   },
    // });
    // const data = response.body;
    // console.log(data);
    // const headers = response.headers;
    // if (data.errcode === 0) {
    //   const pt_key = headers['set-cookie'][1];
    //   this.pt_key = pt_key.substring(pt_key.indexOf('=') + 1, pt_key.indexOf(';'));
    //   const pt_pin = headers['set-cookie'][2];
    //   this.pt_pin = pt_pin.substring(pt_pin.indexOf('=') + 1, pt_pin.indexOf(';'));
    //   this.cookie = 'pt_key=' + this.pt_key + ';pt_pin=' + this.pt_pin + ';';

    //   jdSetInfo({'cookie':this.cookie}).then(rr=>{
    //               if (rr.body == true) {
    //                 this.nickName = this.pt_pin;
    //                 //this.timestamp = new Date(r1.data.timestamp).toLocaleString()
    //                 this.isLogin=true;
    //                 this.$message({
    //                   message: '更新成功',
    //                   type: 'success'
    //                 });
    //               }
    //             })


    //   const result = await this.CKLogin();
    //   result.errcode = 0;
    //   return result;
    // }

    // return {
    //   errcode: data.errcode,
    //   message: data.message,
    // };
    }
  }
}

</script>

<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
.card-title {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
}
.card-subtitle {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(107,114,128,var(--tw-text-opacity));
}
.text-center {
    text-align: center;
}
.card-header {
    border-bottom-width: 1px;
    padding: 1rem;
    text-align: center;
}
.content {
    margin: auto;
    width: 91.666667%;
    max-width: 64rem;
    overflow:hidden;
}
.content {
  @apply w-11/12 max-w-5xl m-auto;
}
.card {
  @apply m-auto mt-5 shadow-lg rounded-lg bg-white;
}
.card-header {
  @apply border-b p-4;
}
.card-title {
  @apply font-medium text-lg;
}
.card-subtitle {
  @apply font-normal text-sm text-gray-500;
}
.card-body {
  @apply text-sm p-4;
}
.card-footer {
  @apply text-right pl-4 pr-4 pb-4;
}
</style>
